import { Link } from 'react-router-dom';
import logoGreen from '../../img/logoGreenNoBG.png';
import Version from '../Version/Version';
import './NavBar.css';

const LoggedInNavBar = ({ users }) => {
  return (
    <>
      {process.env.REACT_APP_PROD === 'false' ? (
        <h1>Testing Environment</h1>
      ) : null}
      <nav className='navbar navbar-expand navbar-dark bg-dark'>
        <Link
          to={'/screener'}
          className='navbar-brand'>
          <img
            alt='logo'
            src={logoGreen}
            width='100'
            height='30'
          />
        </Link>
        <div className='navbar-nav mr-auto'>
          <li className='nav-item'>
            <Link
              to={'/screener'}
              className='nav-link'>
              Tissue Screener Form
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to={'/processors'}
              className='nav-link'>
              Processors
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to={'/hospitals'}
              className='nav-link'>
              Hospitals
            </Link>
          </li>
          <li className='nav-item'>
            <Link
              to={'/tissues'}
              className='nav-link'>
              Tissues
            </Link>
          </li>
          <Version />
        </div>
        <div
          className='ms-auto'
          style={{ marginRight: '2%' }}>
          <button
            className='btn'
            style={{ backgroundColor: '#d7d2cb' }}>
            Logged in as {users.newUser.displayName}
          </button>
        </div>
      </nav>
    </>
  );
};

export default LoggedInNavBar;
