import logoGreen from '../../img/logoGreenNoBG.png';
import './NavBar.css';

const LoggedOutNavBar = ({ devSignIn, signIn }) => {
  return (
    <div>
      {process.env.REACT_APP_PROD === 'false' ? (
        <h1>Testing Environment</h1>
      ) : null}
      <nav className='navbar navbar-expand navbar-dark bg-dark '>
        <img
          alt='logo'
          src={logoGreen}
          width='100'
          height='30'
        />
        <div className='navbar-nav mr-auto'></div>
        <div
          className='ms-auto'
          style={{ marginRight: '2%' }}>
          <button
            onClick={() =>
              process.env.REACT_APP_DEV === 'true' ? devSignIn() : signIn()
            }
            className='btn'
            style={{ backgroundColor: '#c8db2a', float: 'right' }}>
            Log In
          </button>
        </div>
      </nav>
    </div>
  );
};

export default LoggedOutNavBar;
